body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 1200px) {

  .bgimg {
    background-image: url('./images/Headshot_WEB.png');
    background-position: right top;
    background-size: contain;
  }

}

/*Hide in Other Small Devices */


/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

  .bgimg {
    background-image: none;
  }

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

  .bgimg {
    background-image: none;
  }

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

  .bgimg {
    background-image: none;
  }

}

/* Portrait phones and smaller */
@media (max-width: 480px) {

  .bgimg {
    background-image: none;
  }

}
